<template>
  <div class="box">
    <header class="search-top">
      <div>
        <div>
          <span>测试对象：</span>
          <el-select v-model="params.target" placeholder="请选择">
            <el-option :value="1" label="用户昵称"></el-option>
            <el-option :value="2" label="用户搜索"></el-option>
            <el-option :value="3" label="商品标题"></el-option>
            <el-option :value="4" label="商品详情"></el-option>
          </el-select>
        </div>
        <div>
          <span>检测文本：</span>
          <el-input v-model="params.content" placeholder="请输入检测文本" @keyup.enter.native="test"></el-input>
        </div>
        <el-button @click="test">检测</el-button>
      </div>
      <el-button @click="$router.back()">返回</el-button>
    </header>
    <div class="word-detail-box">
      <DragTable :list-query="list" :header="header"></DragTable>
    </div>
    <footer class="word-detail-footer">
      <span>检测结果：<span v-if="pass !== ''" class="word-color" :class="{'red': !pass,'green': pass}">{{pass? '通过' : '不通过'}}</span></span>
    </footer>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  setup(props,{ root }) {
    const pass = ref('')
    const params = reactive({
      target: 1,
      content: ''
    })
    const header = [
      { name: "词库", value: "dic_type" },
      { name: "词条", value: "trigger_word" },
    ];
    const list = reactive({ data: [] });
    const test = () => {
      if(!params.content) {
        root.$message.warning('请输入检测文本')
        return
      }
      root.$axios
        .post("/rcp/dictionaryManage/dictionary/check", params)
        .then((res) => {
          list.data = res.data.list
          pass.value = res.data.result
        })
        
    }
    return {
      params,
      list,
      header,
      pass,
      test
    }
  },
})
</script>


<style lang="scss" scoped>
.box{
  height: 100%;
  width: 100%;
}
.search-top {
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    &>div{
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.word-detail-content{
  padding: 15px;
  width: 100%;
  height: 100%;
}
.word-detail-footer{
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>